body,html{
  margin: 0;
  padding: 0;

}
.outerWrapper {
  border-top: 1px solid black;
}

#textWrapper {
  padding-bottom: 300px;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
td {
padding: 5px
}
.navbar{background-color: white;;}

.variant {
border-color: red;
color: red
}
.invariant {
border-color: black;
color: black;
}
.chosen {
color: green
}
// .unchosen{

// }
.reason{
cursor: pointer;
}
.reason:hover{
text-decoration: underline;
}
.hidden{
display: none;
}
.lbp-link{
color:inherit;
text-decoration: none;
}
.lbp-link:hover{
text-decoration: underline;
}
#imageViewer{
height: 200px; 
position: fixed; 
bottom:0%;
width:100%; 
background-color: #ffffff; 
opacity: 1;
display: none;
box-shadow: 3px 0px 7px #888888;
text-align: center;
padding: 10px;
}
.imageCloseBar{
  //width: 100%; 
  //background-color: rgb(189, 189, 189);
  //height: 10px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  left: calc(100% - 16px);
  top: 0;
  position: absolute;
  opacity: 0.7;
  color: rgb(84, 84, 84);
}
.imageCloseBar:hover{
  opacity: 1
}
.imageViewWrapper{
  box-shadow: -1px 6px 10px 0px rgb(0 0 0 / 65%);
  background-color: white;
  
}
.seg{
  cursor: pointer;
}
.seg:hover{
  text-decoration: underline;
}
