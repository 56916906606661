.itw-text-wide {
  text-align: justify;
  font-size: 16px;
  overflow: visible;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  white-space: inherit;
}
.itw-text-wide:after{
  content: "";
    display: inline-block;
    width: 100%;
}
.itw-text-thin{
  
}