.surfaceWrapper {
  display: flex;
  justify-content: center;
}
.manifestationsList{
  width: 400px;
  text-align: center;
  margin: 10px auto;
  
}

.image-display-choice{
  padding-right: 10px;
}
